import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const LowIron = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Iron In Your Soul" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Iron In Your Soul</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Mar. 31st, 2024) </p>
        </div>
        <div className="articleBody">
          <p>
            There’s a song that my middle school music teacher (s/o Mr. Travis)
            used to have us sing all the time: <br /> <br />
            🎵{" "}
            <i>
              Make new friends, but keep the old. <br /> One is silver, and the
              other gold.
            </i>{" "}
            🎵
          </p>
          <p>
            I’ve{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/useful-triangle"
              target="_blank"
              rel="noopener noreferrer"
            >
              theorized
            </a>{" "}
            about friendships in the past. I’ve enjoyed writing with and about
            friends before, too (see me{" "}
            <a
              href="https://shoya.co/shoyaright/vol1/drogas"
              target="_blank"
              rel="noopener noreferrer"
            >
              pressuring
            </a>{" "}
            Dave to{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/the-arrival-of-drogas"
              target="_blank"
              rel="noopener noreferrer"
            >
              put out music
            </a>
            , or{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/top5-anime"
              target="_blank"
              rel="noopener noreferrer"
            >
              waxing about anime
            </a>{" "}
            with Bediako). But I would find it hard to express just how honored
            I am to continue that practice here and now.{" "}
          </p>
          <p>
            Over the years, I’ve made occasional references{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/black-nonfiction-cinema"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            and{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/discipline-why-how"
              target="_blank"
              rel="noopener noreferrer"
            >
              there
            </a>{" "}
            to the otherworldly entity known to some as Spacebug. Given my
            frequent use of{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/shoya-borealis"
              target="_blank"
              rel="noopener noreferrer"
            >
              myth
            </a>
            , I wouldn’t fault you for thinking that was some fictional
            character. Lucky for us all, that’s not the case. Spacebug is a
            moniker that belongs to my dear childhood friend Tia, now better
            known as{" "}
            <a
              href="https://www.instagram.com/low1ron/"
              target="_blank"
              rel="noopener noreferrer"
            >
              low iron
            </a>
            : DJ and tastemaker extraordinaire. I can say with unbridled
            confidence and pride that no one spinning in our City of Brotherly
            Love and Sisterly Affection is more curious, more generous, or more
            soulful than Tia.{" "}
          </p>
          <p>
            The following interview was completely impromptu. We were just
            catching up over some Thai food at a picnic table in Hunting Park. A
            nearby{" "}
            <a
              href="https://www.instagram.com/phillybikelife215_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              bike life
            </a>{" "}
            gang was taking advantage of a warm November day. Tia was wearing a
            jade hoodie over a white tee and some medium-wash denim jeans. Her
            acrylic nails were painted bright blue. Her hair was laid. Her
            lashes were popping, complimenting the hazel eyes I’d seen into
            thousands of times.
          </p>
          <p>Our conversation has been condensed and edited for clarity.</p>
          <p>–––</p>
          <p>
            ...Like techno, house music all that stuff. It’s, like, a Black
            thing but a lot of people don’t know that. Even with this revival of
            like, uh, you know, mainstream people tryna do the electronic dance
            kinda thing. People still regard certain things as white people
            music, you know what I mean? And that’s fine, you know, that’s
            totally fine, I understand. Because some of it <i>is</i>
             white people music. I mean, specifically the lower quality music
            [LAUGHTER]. Excuse me. No, but seriously though.
          </p>
          <p className="editorsNote">
            Wait, hold up. [LAUGHTER] This is too good, do you mind if I record
            this?
          </p>
          <p>No, go ahead.</p>
          <p className="editorsNote">Okay, what were you saying?</p>
          <p>
            {" "}
            There’s so many white artists coming out now that are tryna make
            this tech, house, whatever kinda stuff. And they’re very much
            inspired by the OG’s, you know, like the ghetto house artist
            pioneers. But you can tell the difference. It’s interesting like,
            white people use a vocal sample in the weirdest way. Cuz they don’t
            really understand the lingo. So when they use a sample, they use it
            weird. Whereas a Black artist, when they use the sample, it’s like,
            okay, "This person is talking about <i>this</i> in this little
            soundbite." So [they] can use it properly or more effectively to
            really like, say something or do something or punctuate a sound. You
            know, white people, because they’re not familiar with the slang,
            they think you talking bout <i>this</i> when you talking bout{" "}
            <i>that</i>.
          </p>
          <p className="editorsNote">
            [LAUGHTER] Do you think they’re, like, willfully ignorant?
          </p>
          <p>
            Good question. And that brings me to [LAUGHTER]. This past Sunday,
            and much love to all the people involved and everything, but it was
            at this place called [REDACTED] on [REDACTED]. But you can tell the
            person who put it on had a lot of resources, cuz his setup was
            crazy. And he’s like a young guy, wer’e probably the same age you
            know what I mean? And he just has access to all this stuff, all
            these gadgets and things like that, to make the show awesome. And I
            was very grateful to be there and all, but I was like "Damn!"
          </p>
          <p className="editorsNote">It's a pretty small venue, right? </p>
          <p>
            Yeah but it’s a good spot for a lil shindig, it was a Sunday. It was
            really an awesome show. He had the whole nine yards. Meanwhile I had
            my gig, my one-year anniversary of{" "}
            <a
              href="https://www.instagram.com/b4ssdownlow/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>bass down low</i>
            </a>{" "}
            maybe a few weeks before, and I had to scrounge up all my little
            resources to try to make that special. And in the end, we didn’t
            even have any candy!!! So the difference in resources is blatant.
            But you know, that doesn’t make me feel like I’m gonna stop or
            anything. If anything, it makes me wanna be like: "It’s cool. It’s
            cool. I’ll be coming around the mountain!" [LAUGHTER]
          </p>
          <p className="editorsNote">[LAUGHTER] </p>
          <p>
            So anyway, that’s just some backgorund about the event. But the
            thing is, right before my set, it was these two young white people
            playing shit like "
            <a
              href="https://www.youtube.com/watch?v=7TPXw34rLs8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hands Up Thumbs Down
            </a>
            " and all that Baltimore club stuff I used to hear growing up at
            block parties or at somebody’s birthday or whatever. And I just
            found that strange because I was the only Black person in the space
            and I’m thinking to myself like: "You don’t even know! You don’t
            even know! You don’t even KNOW!" I wanted to ask them, "How do you
            know about that?" And I mean, it was technically sound, you know.
            But therein lies the difference really. because white people, the
            way they approach it, they can put together a technically sound mix
            and - I mean I could do that too, I could make it clean too - but I
            think our goals are kind of different in how we paint the picture.
            I'm okay with it sounding a little textured and a little messy and
            inviting the conversation of like "This person is about to switch it
            up." Or like, I’m giving you that tease. I feel like that’s a normal
            way of interacting that I had witnessed from DJs growing up. Like,
            that's the way this music was intended to be used.
          </p>
          <p className="editorsNote">
            So you're saying that they usually value being precise-
          </p>
          <p>Yes.</p>
          <p className="editorsNote">-or having a more clinical sound?</p>
          <p>
            Yes, yes, yes, absolutely. And if you look closely, I feel like you
            can see that. They’re very averse to, like, the crowd hearing those
            textures. It’s much more flat. It’s much more like, a continuous{" "}
            <i>untz, untz, untz</i>. I feel like that’s their goal. But I mean,
            DJs like me, we came in and kinda was like "No, we’re gonna
            introduce you to something a little bit different." But that’s
            something that I was exposed to growing up. And the music they’re
            playing, I feel connected to it because of where I heard it and my
            personal history. But it's also, like, literally the people who are
            making the music and how they’re making it, like, ya’ll can’t
            relate! I’m sorry, y’all can’t relate!
          </p>
          <p className="editorsNote">[LAUGHTER]</p>
          <p>
            Even me, going to different places and being on different bills and
            playing alongside different people. I’ve kinda seen it change over
            time.
          </p>
          <p className="editorsNote">Change how? What do you mean?</p>
          <p>
            Yeah so that thing that I was talking about, people wanting to be
            more smooth and clinical. When I first started DJing, people were
            saying to me, "You’re kinda all over the place, but that’s why
            people love you. Cuz you’re not giving us the same{" "}
            <i>untz, untz, untz</i>." It didn’t seem that way to me cuz all
            these [sounds] are connected, so I’m not really all over the place.
          </p>
          <p className="editorsNote">
            So do they mean this as a compliment when they say it? Or is it
            meant as like, constructive feedback?
          </p>
          <p>
            Oh no yeah, it’s a compliment. I think for some people, [my sound]
            is not what they’re used to, but in the end they receive it well.
            And a lot of others are like "Finally!", and that’s for many
            reasons: I tend to play things that are familiar but are just
            outside what you might have listened to recently or what you’re used
            to hearing [in a party setting]. And the other reason is that
            they’re not used to seeing someone who looks like me up there doing
            that. Wait like, Zuff, oh my Goood I played at a function at
            [REDACTED] recently and the guy requested like, “Drake, Travis
            Scott, house kinda vibes”.
          </p>
          <p className="editorsNote">[LAUGHTER]</p>
          <p>
            So I’m like, "Okay whatever." I came with my little bit of Drake,
            Travis, this and that. But I’m like, I know what’s gonna reach you,
            even if you don’t know that yet. And I’m playing, playing a couple
            songs, and the guy comes over to me and says: “Don’t be afraid to
            get a little ratchet.”
          </p>
          <p className="editorsNote">Ayo c'mon!!!</p>
          <p>
            No, you feel me right?!!! I mean this was a Black person saying
            this, but sometimes it be your own people profiling you! There’s
            this idea about what a Black DJ, or even a Black <i>girl</i> DJ is
            gonna play. But actually, no! I’m gonna take you back. Way back.
            Actually show you something different that’s beyond this current
            reintroduction of what “house” or whatever actually is. And it’s
            still Black.
          </p>
          <p className="editorsNote">
            I’m fascinated by all this cuz, as you know, I’m much more of a
            morning lark, I don’t really get out at night that much. There’s
            this book that I’ve been meaning to read, a book on film criticism
            called{" "}
            <i>
              <a
                href="https://valsec.barnesandnoble.com/w/artists-in-the-audience-greg-taylor/1121460841"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Artists in the Audience
              </a>
            </i>
            . I guess I’m thinking of it now because I think DJs, like film
            critics, don’t get their just due as seriously knowledgeable artists
            in their own right. Like, you in particular are a crate-digging ass
            person, you really know the music! And it sounds like you’re not
            given that credit sometimes. You get pigeonholed, is that fair to
            say? Cuz you be deep in the bowels of the record store [LAUGHTER].
          </p>
          <p>
            Yes! I know the inner bowels of the music! The loins of the music
            [LAUGHTER].
          </p>
          <p className="editorsNote">
            [LAUGHTER] Yeah, exactly! You’re an artist tryna exist at the
            bleeding edge of what’s popular, and then bread-crumbing the
            audience somewhere they don’t usually go.
          </p>
          <p>
            Yeah, and that’s the thing. I’ve been thinking about this since I
            started. When you tell people you’re a DJ, people automatically are
            like, "Oh, will you do my cousin’s baby shower? Will you do my son’s
            birthday party?" and stuff like that and I’m like "…I could? But I
            don’t want to." You know? [LAUGHTER]. People think of it as just a
            job, and that anybody can do it. And that it’s one size fits all.
            Right now, we’re in a moment where that’s starting to get broken
            down. So since early on I’ve been dealing with that fork in the
            road: do I want to treat it as a job or really pursue it as an art
            form? And ultimately, I can’t be the one to just come in and just,
            press play.
          </p>
          <p className="editorsNote">
            And you're saying that's changing? Is that particularly in Philly?
          </p>
          <p>
            Yeah, it’s changing everywhere but especially in Philly. Audiences
            are hungrier and smarter and want you to do your homework. For
            instance with <i>bass down low</i>, we’re playing a lot of Miami
            bass and juke, and people haven’t been used to listening to that for
            a while. And it’s been so fun! People have been having fun partying
            to it. It’s fun to reintroduce these sounds to people.
          </p>
          <p className="editorsNote">
            That leads me to something that I’ve been wanting to ask you about.
            I haven’t had my finger on the pulse, but before I stopped using
            socials I was starting to feel like everything was starting to blend
            together, and that there was no such thing as distinct regional
            cultures anymore. Like, it was all just one big Internet blob of
            slang and dances and music. do you still think there’s a such thing
            as "regional music"?
          </p>
          <p>
            There’s definitely still regional music going on, but something that
            I’ve noticed as an issue with that is, particularly in Philly,
            there’s a lack of reporting going on and music journalism that helps
            clarify what the boundaries are of the culture that’s being made
            here. I was recently talking to someone from the <i>Inquirer</i> who
            was{" "}
            <a
              href="https://www.inquirer.com/arts/inq2/philly-djs-songs-summer-playlist-20230912.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              putting together a playlist
            </a>
            . And we got to talking about the new wave of dance music that the
            youth in Philly are making, and how without people documenting it,
            it’ll easily get swept into the mainstream Internet culture without
            giving those Philly youth the credit. And it’s hard to do, since
            things move so fast, but there’s a lack of stenographers to record
            these things.
          </p>
          <p className="editorsNote">I see.</p>
          <p>
            For instance, a friend who I work with on <i>bass down low</i>{" "}
            recently reached out asking me if I know when Club 923 closed. And
            923 was a big part of club culture and party music here.
          </p>
          <p className="editorsNote">Where was it?</p>
          <p>
            {" "}
            Near Broad and Girard. There’s another club at that location now,
            Warehouse on Watts, which is way different. They have like, punk
            shows and goth night and stuff like that there now. And when he
            asked me about when it closed - and 923 was a bit before my time, so
            I didn’t know off top - I went to search for it, and there’s
            literally no trace of Club 923 closing down online. Which is wild to
            me, how something so important could be lost to history like that.
            I’d have to track down someone who used to party there to find out.
            And it wasn’t even that long ago, like damn. It’s become on of those{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/is-cool-a-virtue"
              target="_blank"
              rel="noopener noreferrer"
            >
              if you know, you know
            </a>{" "}
            kinda things.
          </p>
          <p className="editorsNote">
            Wow. And as far as the Philly youth thing, you mean like the
            youngbuls who invented{" "}
            <a
              href="https://www.youtube.com/watch?v=8J6CL6UULZI"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shake That Shit
            </a>{" "}
            and Getting Stiff? And the general Jersey club vibe that’s been
            making a comeback?
          </p>
          <p>Yup, that's right.</p>
          <p className="editorsNote">
            And have the white TikTok girls started doing those dances or is it
            still low key?
          </p>
          <p>Yeah they do!</p>
          <p className="editorsNote">NO WAY!!!</p>
          <p>They do, and they do it better than me! [LAUGHTER] FUCK!!!</p>
          <p className="editorsNote">[LAUGHTER] BIG FUCK [LAUGHTER]!!!</p>
          <p>DAMMIT!!!! [LAUGHTER]</p>
          <p>––– THE NEARBY BIKE-LIFERS REV THEIR ENGINES –––</p>
          <p className="editorsNote">
            I think I know the answer to this, but what's the difference between
            the culture around borrowing and sampling music that's very much the
            bedrock of hip hop, and the way people borrow sounds today?
          </p>
          <p>
            I mean first off there's a clear racial and class divide. There's a
            difference between sharing and co-optation. [The process of
            co-opting] has even become formulaic at this point: pick a cool name
            that's obscure and Black-sounding - and of course,{" "}
            <a
              href="https://en.wikipedia.org/wiki/Itutu"
              target="_blank"
              rel="noopener noreferrer"
            >
              cool is black
            </a>{" "}
            - and the names really do sound cool. Like, how did you even get
            that name, a hip hop name generator? There's this one artist, he's
            real, but [let's call him] Brickboy Murda. And he's white! And once
            I found that out, it all clicked because the sounds were, like we
            talked about earlier, technically proficient to the point of being
            clinical. Clearly the type of dude who went to engineering school
            and got his certificate type shit. So it sounds kinda robotic, like
            you took it and distilled it from its original context. Still, it
            sounds good sometimes. But the point is, this is the next step in
            the formula, once you use the cool name [and hide being rich and/or
            white], after you get passed that threshold of whatever level of
            success, you change. Then it's "formerly known as Brickboy Murda, I
            actually go by Daniel now."
          </p>
          <p className="editorsNote">
            [LAUGHTER] Wow. So they ride the wave until it doesn't serve them
            anymore.
          </p>
          <p>
            Seriously, you see this so much. The rebranding is blatant. Like, he
            really shouldn't have been Brickboy Murda in the first place!
          </p>
          <p className="editorsNote">
            This kinda reminds me of the whole "Hands Up Thumbs Down' experience
            you had, too. Between the two of us, we've probably been to like,
            10,000 cookouts in our lives. And so we discover music that way
            sometimes, in addition to doing deep dives online or in record
            shops. But for people like them, how are they even able to
            contextualize the music?
          </p>
          <p>
            {" "}
            Good question. This reminds me of an experience I had the other day.
            I was riding the train, and this lady was with her kids, and she had
            a speaker playing music real loud.
          </p>
          <p className="editorsNote">Aw man.</p>
          <p>
            No, no, I wasn't even mad though! Any time somebody new got on the
            El, within the sphere of the music, she would encourage them to
            dance. And all different kinda people was coming up and breaking
            that shit down [LAUGHTER]. And I was like "Wow, that's so cool." And
            so to answer your question, I'm not sure if those other DJs 
            <i>are</i> contextualizing the music.
          </p>
          <p className="editorsNote">I see.</p>
          <p>
            So for me, there's a clear line there between those people who want
            to be good at music for the sake of being technically good at music,
            and those of us who want to be good at music for the sake of
            togetherness.
          </p>
          <p className="editorsNote">
            Damn, you're so right!!! And was it that togetherness that first
            drew you to wanting to DJ? Can you remember what that first memory
            was, or what those early experiences were like?
          </p>
          <p>
            I just remember going to a lot of different events, where the sounds
            people were playing were making me wanna move. And then me moving
            made other people wanna move, and I thought that was so cool. But
            then also, I was listening a lot, and felt like I was discovering
            these hidden messages in songs, a lot of uplifting and encouraging
            messages. There are so many songs that sound like God is speaking to
            you, like "Here you go, I'm right on time." You know? "Keep going,
            you can get through this." There are so many beautiful songs I can
            think of that have a message like that, and that helped me in a time
            of need. And given the circumstances of my life, I've needed those
            kinds of messages. And not to make everything "Us vs. Them" as far
            as rich white DJs, but many of them don't <i>need</i> those kinds of
            messages, so the messages in the music stay hidden to them. Which is
            something that Black people have done forever, leaving each other
            clues in music, like a secret code. And so when I'm putting these
            songs together, it literally becomes a conversation between these
            different messages. and I'm filling the room with these sound waves,
            and the people can feel it.
          </p>
          <p className="editorsNote">
            Damn, so it sounds to me almost like you're solving a spiritual
            puzzle in the room, and if you can get the order of the sounds just
            right, you can unlock that <i>*THING*</i> for people.
          </p>
          <p>Yes, exactly!!!</p>
          <p className="editorsNote">
            Wow, yeah, I gotta come see you play ASAP. I've just been listening
            to your mixes on my own but there's clearly more going on live. Can
            you tell me more about <i>bass down low</i>?
          </p>
          <p>
            Yeah, so I was basically thinking about what was missing on the
            scene. And I was wanting to understand [the legacy] I was a part of
            so I could add to it. I didn't just wanna hop on and be a DJ and
            start making money cuz it's popping or whatever. That’s empty. I
            wanted to take it seriously, cuz I know this is a very important
            thing, a very sacred thing like… Controlling the vibe for however
            long? That’s important. So I was thinking about what I could add,
            and this particular venue, The Dolphin, approached me and was like
            "Hey, if you can come up with a , then you can have this night." And
            I was like “Okay, cool!” It was a very nice thing. And it was a
            full-circle thing cuz the person that did that for me was a DJ that
            I used to go and see before I started DJing and I was like "You’re
            so good!" And I kept up with them. and to have that person be the
            one to offer me this felt so good!
          </p>
          <p className="editorsNote">Do you mind sharing who?</p>
          <p>
            It was{" "}
            <a
              href="https://www.instagram.com/wassupgina/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gina
            </a>
            ! And yeah so I was very much into Miami bass at the time, and I was
            looking into things that were just beyond our recent memory. Things
            like Uncle Luke's "
            <a
              href="https://www.youtube.com/watch?v=89OeP4NBvuA"
              target="_blank"
              rel="noopener noreferrer"
            >
              I Wanna Rock
            </a>{" "}
            ", 2 Live Crew - I was like, “This could definitely get people
            dancing.” And those songs are still getting played, "I Wanna Rock"
            is getting played, and it’s bringing out all kinds of people! So I
            figured maybe that’s what’s missing: regional music. That’s how we
            can fill this space, that’s what I can add - introducing Black music
            into The Dolphin where they have things like Abba Night and Lady
            Gaga Night, but there wasn't much like this. So bringing regional
            club music into the perspective, it even made me better at geography
            [LAUGHTER]. I had to look at a map! And I can mix regions together
            too, I don’t just have to play all Chicago house or Detroit techno
            or Miami bass, [I can] celebrate all these different Black musical
            movements all at once. Instead of that usual <i>untz, untz, untz</i>{" "}
            that can happen a lot in certain venues. So yeah, that was the idea
            behind <i>bass down low</i>. I was tryna capture people’s
            imagination and do something a little different from all the other
            parties people might go to.
          </p>
          <p className="editorsNote">And how long has it been going on?</p>
          <p>
            It’s been a year now. We just had our last one at The Dolphin on
            Halloween. Hold up, let me show you my penguin costume. <br />
            *PULLS UP PENGUIN COSTUME PHOTO*
          </p>
          <p className="editorsNote">[LAUGHTER] You snapped!!! </p>
          <p>
            And yeah the people that I collaborate with on <i>bass down low</i>,
            I work with{" "}
            <a href="https://www.instagram.com/kristian_cho/">nail salon</a> and{" "}
            <a
              href="https://www.instagram.com/phreakwency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              phreakwency
            </a>
            , I love our collaboration. And I think all our names are special
            and cool. Never put the DJ in front [LAUGHTER]. And of course you
            know why I picked the name "low iron".
          </p>
          <p className="editorsNote">
            You gotta let the people know, this is my claim to fame! [LAUGHTER]
          </p>
          <p>
            I mean when we were young, like in high school, you and me were
            gonna start a band called Low Iron!{" "}
          </p>
          <p className="editorsNote">
            You heard it here first, folks!!! [LAUGHTER]. That’s a rare fact
            right there. We were bonding over our{" "}
            <a
              href="https://gi.md/test-colonoscopy/the-top-ten-signs-you-may-be-suffering-from-iron-deficiency#:~:text=Cold%20Hands%20and%20Feet%3A%20Poor,what%20nails%20are%20made%20of."
              target="_blank"
              rel="noopener noreferrer"
            >
              poor circulation
            </a>{" "}
            and realized we both had low iron. I remember designing that logo
            for us and everything!
          </p>
          <p>
            [LAUGHTER] And the band could still happen honestly, never say
            never!
          </p>
          <p className="editorsNote">
            I would love that. Speaking of which, I wanted to ask you: if you
            could learn to play any instrument, which would you choose?
          </p>
          <p>
            Ummm… Whenever Muriel used to{" "}
            <a
              href="https://www.youtube.com/watch?v=rLqSWytNZkg"
              target="_blank"
              rel="noopener noreferrer"
            >
              pull out the sitar
            </a>{" "}
            in <i>Courage the Cowardly Dog</i> that shit would crack my melon!!!
            So imma have to go sitar. [LAUGHTER]
          </p>
          <p className="editorsNote">[LAUGHTER] Incredible.</p>
          <br />
          <p>–––</p>
          <p>
            A few months later, Tia, Sia, Cass, and I all hopped into my
            precious{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/shoyotathon-23"
              target="_blank"
              rel="noopener noreferrer"
            >
              9Runner
            </a>{" "}
            and rode together to{" "}
            <a
              href="https://tworobbersfishtown.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Two Robbers
            </a>{" "}
            in Fishtown for Tia’s set at{" "}
            <a
              href="https://frogradio.online/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FROG RADIO
            </a>
            . I couldn’t contain my excitement, I’d finally get to see the
            artist at work, better yet the friend at play. I braced myself to
            stay up well past my usual bedtime, and it was well worth it. Her
            charisma and finesse at the 1s and 2s was a spectacle in itself. My
            sweat ran, my limbs popped, my spirit sang in a chorus with a
            hundred strangers.{" "}
          </p>
          <p>
            After Tia’s set, not long into a set by{" "}
            <a
              href="https://www.instagram.com/gum.mp3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              gum.mp3
            </a>
            , I crushed some french fries and got ready to drop Tia off at her
            next gig, at an event called{" "}
            <a
              href="https://philly700.com/event/ordinary-world-with-kobwe-bratty-b-and-lowiron/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ordinary World
            </a>{" "}
            at The 700. It’s not unusual for her to be booked back to back like
            this, I discovered. She told me, “In this next set, I’ll get to
            experiment more with the sounds I use. The crowd is a bit more
            eclectic, so I can let loose.”
          </p>
          <p>
            And she wasn’t kidding. What I witnessed next was akin to Rock Lee{" "}
            <a
              href="https://youtu.be/Ev24dNdzyN4?si=ueh4DPg60PnHeoPo&t=48"
              target="_blank"
              rel="noopener noreferrer"
            >
              dropping his weights
            </a>{" "}
            against Gaara . I couldn’t describe to you what I was hearing, but I
            was suddenly levitating despite my heavy eyelids.{" "}
          </p>
          <p>
            As I sit here now, transcribing our conversation from a cafe in the
            Little Tokyo neighborhood of Mexico City (at my absolute coolest,
            really 🇲🇽😎🇯🇵), I’m reminded of something else Tia said to me that
            night. I had been ranting and raving and singing her praises after
            her first set. I felt like, despite being a homebody, I finally{" "}
            <i>got it</i>. With a knowing smile, Tia looked at me and said
            “Zuff, that’s just the tip of the iceberg.”{" "}
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default LowIron
